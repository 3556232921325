import React from "react";
import { Row, Col } from "react-bootstrap";

type InlineJustify = "center" | "left" | "right";

export const Inline: React.FC<{
  children: React.ReactElement | React.ReactElement[];
  justify: InlineJustify;
  width: string | number;
}> = ({ children, justify, width }) => {
  if (!children || typeof children !== "object") {
    return <Row></Row>;
  }

  return (
    <Row
      className={`justify-content-md-${justify} text-${justify} align-self-${justify}`}
      style={{
        width: width,
        margin: justify === "center" ? "0 auto" : "inherit",
      }}
    >
      {React.Children.map(children, (child: React.ReactElement) => (
        <Col className={`align-self-${justify}`}>{child}</Col>
      ))}
    </Row>
  );
};
