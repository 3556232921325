import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useMemo,
  RefObject,
} from "react";
import { Row, Card, Col, Button, Jumbotron, Container } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import firebaseAnalytics from "../utils/firebaseConfig";
import { logEvent } from "firebase/analytics";
import Loader from "react-loader-spinner";
import axios from "axios";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";
import useWindowSize from "./useWindowSize";
import { SoonspinsDataContext } from "./SoonspinsDataProvider";
import { AudioPlayerHandle, playMixcloudAudio } from "./BodyContainer";
import { MixcloudEmbed } from "./Body";
import { NUM_TO_MONTH, SoonspinsShow } from "../util";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";

const ArchivesDropdownWrapper = styled.div`
  padding: 12px;
  max-width: 350px;
  align-content: center;
  margin: 0 auto;
  padding: 0px;
`;

const SoonspinsCard: React.FC<{
  contentObject: SoonspinsShow;
  audioPlayerRef: RefObject<AudioPlayerHandle>;
  imageSize?: string;
}> = ({ contentObject, audioPlayerRef, imageSize = "300px" }) => {
  return (
    <Card className="soonspins-card">
      <Card.Img
        variant="top"
        src={contentObject.image_url}
        width={250}
        height={250}
      />
      <Card.Body>
        <Card.Title>
          <div style={{ marginBottom: 12 }}>{contentObject.title}</div>
          <div
            style={{
              fontSize: 12,
              textShadow: "2px 2px black",
            }}
          >
            {new Date(contentObject.submission_date).toDateString()}
          </div>
        </Card.Title>
        <Card.Text>{contentObject.description}</Card.Text>
        {
          <Button
            onClick={() =>
              playMixcloudAudio(contentObject.embed_code, audioPlayerRef)
            }
            variant="outline-light"
            id="button"
            className="card-button"
          >
            Listen Now
          </Button>
        }
      </Card.Body>
    </Card>
  );
};

const MobileArchivesContainer: React.FC<{
  currentShows: SoonspinsShow[];
  audioPlayerRef: RefObject<AudioPlayerHandle>;
}> = ({ currentShows, audioPlayerRef }) => {
  return (
    <Col style={{ alignItems: "center" }}>
      {currentShows.map((contentObject: SoonspinsShow, index: number) => (
        <Row key={contentObject.id}>
          <SoonspinsCard
            contentObject={contentObject}
            audioPlayerRef={audioPlayerRef}
            key={`${index}_${contentObject.id}`}
            imageSize="300px"
          />
        </Row>
      ))}
    </Col>
  );
};

const ArchivesContainer: React.FC<{
  currentShows: SoonspinsShow[];
  audioPlayerRef: RefObject<AudioPlayerHandle>;
}> = ({ currentShows, audioPlayerRef }) => {
  return (
    <Row xs={4} style={{ justifyContent: "center" }}>
      {currentShows.map((contentObject, index) => (
        <SoonspinsCard
          contentObject={contentObject}
          audioPlayerRef={audioPlayerRef}
          key={`${index}_${contentObject.id}`}
          imageSize="450px"
        />
      ))}
    </Row>
  );
};

const Archives = () => {
  useEffect(() => {
    logEvent(firebaseAnalytics, "archives page visited");
  }, []);
  const { width } = useWindowSize();
  const isMobile = width !== undefined && width < 768;
  const soonspinsDataContext = useContext(SoonspinsDataContext);
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);

  const archivedShows = useMemo(() => {
    return soonspinsDataContext ? soonspinsDataContext.archivedShows : {};
  }, [soonspinsDataContext]);

  const archivedShowsLoading = soonspinsDataContext
    ? soonspinsDataContext.archivedShowsLoading
    : false;

  const [currentTimeFrame, setCurrentTimeFrame] = useState("");

  const currentShows = useMemo(() => {
    if (archivedShows && currentTimeFrame) {
      return archivedShows[currentTimeFrame];
    }
    return [];
  }, [archivedShows, currentTimeFrame]);

  const dropdownOptionsWithCurrentTimeFrameSideEffect: Option[] =
    useMemo(() => {
      if (archivedShows && !archivedShowsLoading) {
        const sortedAndFormattedOptions = Object.keys(archivedShows)
          .sort((a, b) => (a < b ? 1 : -1))
          .reduce((formattedOptions: Option[], unformattedOption) => {
            const [year, month] = unformattedOption.split("_");
            formattedOptions.push({
              label: `${NUM_TO_MONTH[Number(month)]} ${year}`,
              value: unformattedOption,
            });
            return formattedOptions;
          }, []);
        setCurrentTimeFrame(sortedAndFormattedOptions[0].value);
        return sortedAndFormattedOptions;
      }
      return [];
    }, [archivedShows, archivedShowsLoading]);

  const onDropDownChange = (selectedOption: Option) => {
    setCurrentTimeFrame(() => {
      if (selectedOption.value in archivedShows) {
        return selectedOption.value;
      }
      return "";
    });
  };

  if (archivedShowsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div id="page-background">
      <Header />

      <Jumbotron
        fluid
        style={{ background: "transparent", marginBottom: 0, padding: 32 }}
      >
        <Container fluid>
          <h1 className="title-h1" style={{ wordWrap: "break-word" }}>
            ARCHIVES
          </h1>
        </Container>
      </Jumbotron>
      <ArchivesDropdownWrapper className="flex justify-center items-center">
        <Dropdown
          options={dropdownOptionsWithCurrentTimeFrameSideEffect}
          onChange={onDropDownChange}
          value={currentTimeFrame}
        />
      </ArchivesDropdownWrapper>
      <Container fluid style={{ marginBottom: 24 }}>
        {isMobile ? (
          <MobileArchivesContainer
            currentShows={currentShows}
            audioPlayerRef={audioPlayerRef}
          />
        ) : (
          <ArchivesContainer
            currentShows={currentShows}
            audioPlayerRef={audioPlayerRef}
          />
        )}
      </Container>
      <Footer />

      <MixcloudEmbed ref={audioPlayerRef} />
    </div>
  );
};

export default Archives;
