import React, {
  ReactChildren,
  useReducer,
  createContext,
  Dispatch,
  Reducer,
} from "react";
import { SoonspinsShows, SoonspinsShow, DatedSoonspinsShows } from "../util";
import { DataMode, useSoonspinsData } from "../hooks/useSoonspinsData";
import { flatten } from "underscore";

interface SoonspinsDataProviderProps {
  children: React.ReactNode;
  mode: DataMode;
}

enum SoonspinsDispatchActionType {
  ADD_SHOW = "ADD_SHOW",
  REMOVE_SHOW = "REMOVE_SHOW",
  EDIT_SHOW = "EDIT_SHOW",
}

export type ArchivedShows = {
  [key: string]: SoonspinsShow[];
};

export type SoonspinsDataType = {
  shows: SoonspinsShow[];
  showsLoading: boolean;
  archivedShows: ArchivedShows;
  archivedShowsLoading: boolean;
};

type SoonspinsDispatchAction = {
  type: SoonspinsDispatchActionType;
  show: SoonspinsShow;
};

const showsReducer = (
  state: SoonspinsShows,
  action: SoonspinsDispatchAction
) => {
  switch (action.type) {
    case SoonspinsDispatchActionType.ADD_SHOW: {
      return {};
    }
    case SoonspinsDispatchActionType.REMOVE_SHOW: {
      return {};
    }
    case SoonspinsDispatchActionType.EDIT_SHOW: {
      return {};
    }
    default: {
      throw Error("Unrecognized dispatch action type");
    }
  }
};

export const SoonspinsDataContext = createContext<
  SoonspinsDataType | undefined
>(undefined);
export const SoonspinsDispatchContext = createContext<
  Dispatch<SoonspinsDispatchAction> | undefined
>(undefined);

const SoonspinsDataProvider: React.FC<SoonspinsDataProviderProps> = ({
  children,
  mode,
}) => {
  const { shows, showsLoading, archivedShows, archivedShowsLoading } =
    useSoonspinsData(mode);
  const archiveTimeFrames = Object.keys(shows);
  const initialTimeFrame = archiveTimeFrames[0];
  const [currentShows, dispatch] = useReducer(showsReducer, shows);
  return (
    <SoonspinsDataContext.Provider
      value={{ shows, showsLoading, archivedShows, archivedShowsLoading }}
    >
      <SoonspinsDispatchContext.Provider value={dispatch}>
        {children}
      </SoonspinsDispatchContext.Provider>
    </SoonspinsDataContext.Provider>
  );
};

export default SoonspinsDataProvider;
