import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRef, useState, useEffect } from "react";
import Header from "../Header";

import axios from "axios";
import "video.js/dist/video-js.css";
import { Container, Jumbotron, Row, Col, Card, Button } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import "../../Assets/css/default.min.css";
import "../../Assets/css/default.css";
import Loader from "react-loader-spinner";
import { Inline } from "../Layout";
import LoadingSpinner from "../LoadingSpinner";
import { Pages } from "./Layout";
export type GenericObject = { [key: string | number]: any };

const Admin = () => {
  const TITLE = 1;
  const DESCRIPTION = 2;
  const EMBED_CODE = 3;
  const { register, handleSubmit } = useForm();
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [editing, setEditing] = useState(false);
  const [mixCloudPayload, setMixCloudPayload] = useState<GenericObject>({});
  const [editedShows, setEditedShows] = useState<GenericObject>({});
  const [showsDict, setShowsDict] = useState<GenericObject>({});
  const [deleteSet, setDeleteSet] = useState<Set<number>>(new Set([]));
  const [uploadedImage, setUploadedImage] = useState("");
  const [loadingText, setLoadingText] = useState("");
  const [embedWarningText, setEmbedWarningText] = useState("");
  const [pages, setPages] = useState<GenericObject>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [deleteStates, setDeleteStates] = useState<Record<number, string>>({});
  const editText = useMemo(() => {
    return editing ? "SAVE CHANGES" : "EDIT";
  }, [editing]);

  const onSubmit = async (postInfo: GenericObject) => {
    const data = new FormData();
    data.append("image_url", postInfo["imageUrl"][0]);
    data.append("title", postInfo["eventTitle"]);
    data.append("description", postInfo["descriptionText"]);
    data.append("embed_code", postInfo["mixcloudCode"]);
    setLoadingText("Uploading content...");
    axios({
      url: "https://hello-n4jjjbspiq-uc.a.run.app/shows",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${authState.accessToken?.accessToken}`,
      },
      method: "post",
      data: data,
    })
      .then((response) => {
        setLoadingText("Finishing up...");
      })
      .then((response) => {
        setLoadingText("Content uploaded!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    fetch(`https://hello-n4jjjbspiq-uc.a.run.app/shows/${currentPageNumber}`, {
      method: "get",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        organizeShows(data["shows"]);
        setPages(data["pages"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPageNumber]);

  if (authState.isPending) {
    return <div>Loading...bro</div>;
  }

  const organizeShows = (data: any) => {
    const shows: GenericObject[] = Object.values(data);

    setEditedShows(() => {
      let showsObject: GenericObject = {};
      shows.map((show: GenericObject) => {
        showsObject[show["id"]] = show;
        return;
      });
      return showsObject;
    });

    setShowsDict(() => {
      let showsObject: GenericObject = {};
      shows.map((show) => {
        showsObject[show["id"]] = show;
      });
      return showsObject;
    });

    setMixCloudPayload(data);
  };

  const LogoutLoginButton: React.FC = () => {
    return authState.isAuthenticated ? (
      <button
        onClick={() => {
          oktaAuth.signOut();
        }}
      >
        Logout
      </button>
    ) : (
      <button
        onClick={() => {
          oktaAuth.signOut();
        }}
      >
        Login
      </button>
    );
  };

  const onImageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event !== null && event.target.files) {
      setCurrentFile(event.target.files[0]);
      setUploadedImage(event.target.files[0].name);
    }
  };

  const onDeleteClick = (id: number, deleteSet: any) => {
    if (!deleteSet.has(id)) {
      deleteSet.add(id);
      const newDeleteSet: Set<number> = new Set(deleteSet);
      setDeleteSet(newDeleteSet);
    } else {
      deleteSet.delete(id);
      const newDeleteSet: Set<number> = new Set(deleteSet);
      setDeleteSet(newDeleteSet);
    }
  };

  const editShowHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    iD: number | string,
    field: number
  ) => {
    const value = event.target.value;
    switch (field) {
      case TITLE:
        setEditedShows((oldEditedShows: GenericObject) => {
          return {
            ...oldEditedShows,
            [iD]: {
              id: iD,
              title: value,
              description: oldEditedShows[iD].description,
              embed_code: oldEditedShows[iD].embed_code,
              image_url: oldEditedShows[iD].image_url,
              submission_date: oldEditedShows[iD].submission_date,
            },
          };
        });
        break;
      case DESCRIPTION:
        setEditedShows((oldEditedShows) => {
          return {
            ...oldEditedShows,
            [iD]: {
              id: iD,
              title: oldEditedShows[iD].title,
              description: value,
              embed_code: oldEditedShows[iD].embed_code,
              image_url: oldEditedShows[iD].image_url,
              submission_date: oldEditedShows[iD].submission_date,
            },
          };
        });
        break;
      case EMBED_CODE:
        setEditedShows((oldEditedShows) => {
          return {
            ...oldEditedShows,
            [iD]: {
              id: iD,
              title: oldEditedShows[iD].title,
              description: oldEditedShows[iD].description,
              embed_code: value,
              image_url: oldEditedShows[iD].image_url,
              submission_date: oldEditedShows[iD].submission_date,
            },
          };
        });
        break;
      default:
    }
  };

  const compareEntries = (obj1: GenericObject, obj2: GenericObject) => {
    const keys = Object.keys(obj1);

    for (const key of keys) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  const handleEditingSaving = async (editing: boolean) => {
    let keys = Object.keys(editedShows);

    const actualEditedShows = [];
    if (editing) {
      //setEditText("SAVING CHANGES...");
      for (const key of keys) {
        if (!compareEntries(editedShows[key], showsDict[key])) {
          actualEditedShows.push(editedShows[key]);
        }
      }
      const data = new FormData();
      data.append("editedPosts", JSON.stringify(actualEditedShows));
      data.append("deletedPosts", JSON.stringify(Array.from(deleteSet)));
      await axios({
        url: "https://hello-n4jjjbspiq-uc.a.run.app/shows",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${authState.accessToken?.accessToken}`,
        },
        method: "put",
        data: data,
      })
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      setEditing(true);
    }
  };

  const checkForValidEmbed = (embedCode: string) => {
    if (!embedCode.includes("<iframe") || !embedCode.includes("</iframe>")) {
      setEmbedWarningText("WARNING: YOU HAVE NOT ENTERED VALID EMBED CODE");
    } else {
      setEmbedWarningText("");
    }
  };

  const handlePageClick = (pageNum: number) => {
    setCurrentPageNumber(pageNum);
  };

  if (mixCloudPayload.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div id="adminPage">
      <Header />
      <Jumbotron
        fluid
        style={{ backgroundColor: "transparent", marginBottom: 0 }}
      >
        <Container>
          <h1 className="title-h1">ADMIN PANEL</h1>
        </Container>
      </Jumbotron>
      <Container>
        <Jumbotron
          fluid
          style={{ backgroundColor: "transparent", marginBottom: 0 }}
        >
          <Container>
            <h1 className="title-h1">Add Event/Show</h1>
          </Container>
        </Jumbotron>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <label>Event Title: </label>
            </Col>
            <Col>
              <input
                name="eventTitle"
                ref={register({ required: true })}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Description: </label>
            </Col>
            <Col>
              <textarea
                name="descriptionText"
                ref={register({ required: true })}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Upload Your Image </label>
            </Col>
            <Col>
              <input
                type="file"
                name="imageUrl"
                ref={register({ required: true })}
                className="form-control"
                onChange={onImageChangeHandler}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Mixcloud Embed Code </label>
            </Col>
            <Col>
              <textarea
                name="mixcloudCode"
                ref={register({ required: true })}
                style={{ width: "100%" }}
                onChange={(e) => {
                  checkForValidEmbed(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>{loadingText}</Row>
          {embedWarningText === "" ? (
            <Row>
              <input type="submit" />
            </Row>
          ) : null}
          <Row>{embedWarningText}</Row>
        </form>
      </Container>
      <Jumbotron
        fluid
        style={{ backgroundColor: "transparent", marginBottom: 0 }}
      >
        <Container style={{ justifyContent: "center" }}>
          <h1 className="title-h1">Edit Event/Show</h1>
          <Row>
            <Col>
              <Button
                onClick={() => {
                  handleEditingSaving(editing);
                }}
              >
                {editText}
              </Button>
            </Col>
            <Col>
              {editing && (
                <Button
                  onClick={() => {
                    setEditing(editing ? false : true);
                    setEditedShows(showsDict);
                    setDeleteSet(new Set([]));
                  }}
                >
                  CANCEL
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container fluid>
        <Pages
          pages={pages}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
          handlePageClick={handlePageClick}
        />
        <Row xs={4} md={6} lg={8} style={{ justifyContent: "center" }}>
          {Object.values(mixCloudPayload).map((contentObject, i) => (
            <Card key={contentObject["id"]} className="soonspins-card">
              <Card.Img
                variant="top"
                src={contentObject["image_url"]}
                className="card-pic"
              />
              <Card.Body>
                {editing ? (
                  <form style={{ fontSize: 10 }}>
                    <Row>
                      <Col>
                        <textarea
                          name="eventTitle"
                          {...register({ required: true })}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            editShowHandler(e, contentObject["id"], TITLE)
                          }
                        >
                          {contentObject["title"]}
                        </textarea>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <textarea
                          name="descriptionText"
                          {...register({ required: true })}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            editShowHandler(e, contentObject["id"], DESCRIPTION)
                          }
                        >
                          {contentObject["description"]}
                        </textarea>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <textarea
                          name="mixcloudCode"
                          ref={register({ required: true })}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            editShowHandler(e, contentObject["id"], EMBED_CODE)
                          }
                        >
                          {contentObject["embed_code"]}
                        </textarea>
                      </Col>
                    </Row>
                    <Row>{loadingText}</Row>
                  </form>
                ) : (
                  <>
                    <Card.Title style={{ fontWeight: 700 }}>
                      {contentObject["title"]}
                    </Card.Title>
                    <Card.Text>{contentObject["description"]}</Card.Text>
                  </>
                )}
                {editing ? (
                  <Button
                    id={contentObject["id"]}
                    variant={"outline-light"}
                    style={{
                      background: deleteSet.has(contentObject["id"])
                        ? "red"
                        : "transparent",
                    }}
                    className="card-button"
                    onClick={() => {
                      onDeleteClick(contentObject["id"], deleteSet);
                    }}
                  >
                    {deleteSet.has(contentObject["id"]) ? "RESTORE" : "Delete"}
                  </Button>
                ) : null}
              </Card.Body>
            </Card>
          ))}
        </Row>
      </Container>

      <LogoutLoginButton />
    </div>
  );
};

export default Admin;
