import React from "react";
import { useEffect, useState } from "react";
import Header from "./Header";

import "video.js/dist/video-js.css";
import { Container, Jumbotron } from "react-bootstrap";

import firebaseAnalytics from "../utils/firebaseConfig";
import { logEvent } from "firebase/analytics";
import { Row, Col } from "react-flexbox-grid";
import $ from "jquery";

const Livestream = () => {
  useEffect(() => {
    logEvent(firebaseAnalytics, "chat page visited");
  }, []);

  /* useEffect(() => {
        const titleScript = document.createElement("script")
        titleScript.src = "https://embed.radio.co/embed/sa9761af67/song.js";
        titleScript.async = true;

        document.querySelector("#radioco-title-div").appendChild(titleScript);

        return () => {
            document.querySelector("#radioco-title-div").removeChild(titleScript);
        }
    }, []); */

  useEffect(() => {
    const chatScript = document.createElement("script");
    chatScript.src = "//st.chatango.com/js/gz/emb.js";
    chatScript.async = true;

    document.querySelector("#chat-div").appendChild(chatScript);

    return () => {
      document.querySelector("#chat-div").removeChild(chatScript);
    };
  }, []);

  /*useEffect(() => {
        const playerScript = document.createElement('script');
        playerScript.src = "https://embed.radio.co/player/28e1404.js";
        playerScript.async = true;
        
        document.querySelector("#radioco-player-div").appendChild(playerScript);
        
        return () => {
            console.log($("iframe").remove())
        }
    }, []);*/

  return (
    <div id="livestream-page">
      <Header />

      <Jumbotron
        className="mb-5"
        fluid
        style={{
          backgroundColor: "transparent",
          marginBottom: 0,
          paddingBottom: "5px",
        }}
      >
        <Container>
          <h1
            className="title-h1"
            style={{
              wordWrap: "break-word",
              transform: "uppercase",
              textShadow: "5px 5px #1F1E1E",
            }}
          >
            SOONSPINS CHAT
          </h1>
          <h4
            className="title-h1"
            style={{
              wordWrap: "break-word",
              transform: "uppercase",
              textShadow: "5px 5px #1F1E1E",
              fontSize: "1.5rem",
            }}
          >
            Use the chat below to chop it up with others, discuss your favorite
            mixes/tracks or provide us with feedback!
          </h4>
        </Container>
      </Jumbotron>

      <Container
        id="twitch-video"
        style={{
          backgroundColor: "#black",
          color: "white",
          marginBottom: 30,
          alignItems: "center",
          textAlign: "-webkit-center",
        }}
      >
        {/* <div id="radioco-player-div" style={{marginBottom: 30}}><iframe src="https://embed.radio.co/player/28e1404.html" width="100%" allow="autoplay" scrolling="no" style={{border: "none", overflow: "hidden", height: 62}}></iframe></div> */}
        {/* <h1 className="title-h1" style={{marginBottom: 30}}>NOW PLAYING:<div id="radioco-title-div"></div></h1> */}
        <div id="chat-div" onClick={() => console.log("LODE")}>
          <script
            id="cid0020000305013309976"
            data-cfasync="false"
            async
            src="//st.chatango.com/js/gz/emb.js"
            style={{ width: 250, height: 250 }}
            dangerouslySetInnerHTML={{
              __html: `{"handle":"thisissoonspins","arch":"js","styles":{"a":"bd6919","b":100,"c":"FFFFFF","d":"FFFFFF","k":"bd6919","l":"bd6919","m":"bd6919","n":"FFFFFF","p":"10","q":"bd6919","r":100,"t":0,"surl":0,"cnrs":"0.41"}}`,
            }}
          />
        </div>
      </Container>
      <footer
        style={{
          textAlign: "end",
          bottom: 0,
          position: "fixed",
          width: "100%",
        }}
        className="footer"
      >
        <div className="container-fluid">
          © {new Date().getFullYear()} SOONSPINS
        </div>
      </footer>
    </div>
  );
};

export default Livestream;
