import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Ref,
  RefObject,
} from "react";

import firebaseAnalytics from "../utils/firebaseConfig";
import { logEvent } from "firebase/analytics";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  SoonspinsDataContext,
  SoonspinsDataType,
} from "./SoonspinsDataProvider";
import Body from "./Body";
import { SoonspinsShow } from "../util";

export type AudioPlayerHandle = {
  hide: () => void;
  show: () => void;
  html: (html: string) => void;
};

export const playMixcloudAudio = (
  embedCode: string,
  audioPlayerRef: RefObject<AudioPlayerHandle>
) => {
  const sounds = document.getElementsByTagName("audio");
  for (let i = 0; i < sounds.length; i++) sounds[i].pause();
  audioPlayerRef!.current!.hide();
  audioPlayerRef!.current!.html(embedCode);
  audioPlayerRef!.current!.show();
};

const BodyContainer: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const listenText = "Listen Now";
  const audioRef = useRef<AudioPlayerHandle>(null);
  const soonspinsContext = useContext(SoonspinsDataContext);

  const shows: SoonspinsShow[] = soonspinsContext ? soonspinsContext.shows : [];
  const showsLoading: boolean = soonspinsContext
    ? soonspinsContext.showsLoading
    : false;

  const handleSelect = (selectedIndex: number, e: any) => {
    setActiveIndex(selectedIndex);
  };

  useEffect(() => {
    logEvent(firebaseAnalytics, "homepage visited");
  }, []);

  return (
    <Body
      data={shows}
      dataLoading={showsLoading}
      listenText={listenText}
      handleSelect={handleSelect}
      playMixcloudAudio={playMixcloudAudio}
      activeIndex={activeIndex}
      audioRef={audioRef}
    />
  );
};

export default BodyContainer;
