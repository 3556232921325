import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
 
const Footer = ()=> {
    return (
        <footer style={{textAlign: "end"}} className="footer">
      <div className="container-fluid">
        © {new Date().getFullYear()} SOONSPINS
      </div>
    </footer>
    )
} 

export default Footer