// src/RadioContext.tsx

import React, { createContext, useContext, useEffect, useState } from "react";
import { IcecastStatus, Source } from "../types";
import { STATION_BASE_URL } from "../constants";

interface RadioContextType {
  stations: Source[];
  loading: boolean;
}

const RadioContext = createContext<RadioContextType | undefined>(undefined);

export const RadioProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [stations, setStations] = useState<Source[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchStatus = async () => {
    setLoading(true);
    const statusUrl = `${STATION_BASE_URL}/status-json.xsl`;
    try {
      const response = await fetch(statusUrl);
      const data: IcecastStatus = await response.json();
      const sources = Array.isArray(data.icestats.source)
        ? data.icestats.source
        : [data.icestats.source];
      setStations(sources.filter((source) => !!source.server_name));
    } catch (error) {
      console.error("Error fetching status:", error);
      setStations([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
    const interval = setInterval(fetchStatus, 10000); // Fetch every 10 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <RadioContext.Provider value={{ stations, loading }}>
      {children}
    </RadioContext.Provider>
  );
};

export const useRadio = () => {
  const context = useContext(RadioContext);
  if (!context) {
    throw new Error("useRadio must be used within a RadioProvider");
  }
  return context;
};
