import { logEvent } from "firebase/analytics";
import firebaseAnalytics from "../utils/firebaseConfig";
import Header from "./Header";
import { useEffect } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import RadioTicker from "./RadioTicker";

const Radio = () => {
  useEffect(() => {
    logEvent(firebaseAnalytics, "chat page visited");
  }, []);
  return (
    <div id="radio-page">
      <Header />
      <Jumbotron
        className="mb-5"
        fluid
        style={{
          backgroundColor: "transparent",
          marginBottom: 0,
          paddingBottom: "5px",
        }}
      >
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 16,
            }}
          >
            <img
              src="soonspins.png"
              alt="soonspins logo"
              style={{ height: "120px", width: "220px" }}
            ></img>
            <h1
              className="title-h1"
              style={{
                wordWrap: "break-word",
                transform: "uppercase",
                textShadow: "5px 5px #1F1E1E",
                margin: "auto 0",
              }}
            >
              RADIO
            </h1>
          </div>

          <h4
            className="title-h1"
            style={{
              wordWrap: "break-word",
              transform: "uppercase",
              textShadow: "5px 5px #1F1E1E",
              fontSize: "1.5rem",
            }}
          >
            Just listen...
          </h4>
        </Container>
      </Jumbotron>
      <RadioTicker />
    </div>
  );
};

export default Radio;
