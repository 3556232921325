import React, { useImperativeHandle, forwardRef, ElementRef, useRef, RefObject, Ref } from 'react';
import useWindowSize from "./useWindowSize";
import { SoonspinsShow } from '../util';
import SoonspinsBody from './SoonspinsBody';
import LoadingSpinner from './LoadingSpinner';
import SoonspinsCarouselContainer, { MobileSoonspinsContainer } from './SoonspinsCarouselContainer';
import { AudioPlayerHandle } from './BodyContainer';

interface BodyProps {
    dataLoading: boolean;
    data: SoonspinsShow[];
    playMixcloudAudio: (embedCode: string, audioPlayerRef: RefObject<AudioPlayerHandle>) => void;
    handleSelect: (selectedIndex: number, e: any) => void;
    activeIndex: number;
    listenText: string;
    audioRef: RefObject<AudioPlayerHandle>;
}

export const MixcloudEmbed = forwardRef<AudioPlayerHandle>((props, ref) => {
    const audioRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => {
        return {
            hide: () => {
                audioRef.current!.style.visibility = "hidden";
            },
            show: () => {
                audioRef.current!.style.visibility = "visible";
                audioRef.current!.style.display = "inherit";
            },
            html: (html: string) => {
                audioRef.current!.innerHTML = html;
            }
        };
    }, []);
    return (
        <div
            id="outer-playlist"
            style={{ position: "fixed", bottom: 24, width: "100%" }}
            ref={audioRef}>
        </div>
    );
});

const Body: React.FC<BodyProps> = ({ dataLoading, data, playMixcloudAudio, activeIndex, handleSelect, audioRef }) => {
    const { height, width } = useWindowSize();
    const isMobile = width !== undefined && width < 768;

    return (
        <SoonspinsBody>
            {
                dataLoading ? (
                    <LoadingSpinner />
                ) : (
                    !isMobile ? (
                        <SoonspinsCarouselContainer data={data} activeIndex={activeIndex} handleSelect={handleSelect} playMixcloudAudio={playMixcloudAudio} audioPlayerRef={audioRef} />
                    ) : (
                        <MobileSoonspinsContainer data={data} playMixcloudAudio={playMixcloudAudio} audioPlayerRef={audioRef} />
                    )
                )
            }
            <MixcloudEmbed ref={audioRef} />
        </SoonspinsBody>
    );
};

export default Body;