import { useEffect, useState } from "react";
import { IceCastStation } from "../util";
import { isArray } from "lodash";
import { STATION_BASE_URL } from "../constants";

const DATA_URL = `${STATION_BASE_URL}/status-json.xsl`;

export const useIceCastData = () => {
  const [stations, setStations] = useState<Record<string, IceCastStation>>({});
  const [stationsLoading, setStationsLoading] = useState(false);

  useEffect(() => {
    let ignore = false;
    const interval = setInterval(() => {
      setStationsLoading(true);
      fetch(DATA_URL)
        .then((data) => data.json())
        .then((r) => r.icestats.source)
        .then((sources) => {
          if (!isArray(sources)) {
            setStations({
              [sources.server_name]: {
                id: sources.server_name,
                url: sources.listenurl,
                currentTrack: sources.title,
                name: sources.server_description,
              },
            });
          } else {
            setStations(
              sources
                .map((source) => ({
                  id: source.server_name,
                  url: source.listenurl,
                  currentTrack: source.title,
                  name: source.server_description,
                }))
                .reduce((agg, curr) => ({ ...agg, [curr.id]: curr }), {})
            );
          }
          setStationsLoading(false);
        });
    }, 10000);

    return () => {
      ignore = true;
      clearInterval(interval);
    };
  }, []);
  return { stations, stationsLoading };
};
