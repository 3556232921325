import React, { SetStateAction } from "react";
import { Inline } from "../Layout";
import { GenericObject } from "./Admin";

type PagesProps = {
  pages: GenericObject;
  currentPageNumber: number;
  setCurrentPageNumber: (value: number | ((oldVal: number) => number)) => void;
  handlePageClick: (pageNumber: number) => void;
};

export const Pages: React.FC<PagesProps> = ({
  pages,
  currentPageNumber,
  setCurrentPageNumber,
  handlePageClick,
}) => {
  return (
    <Inline justify="center" width={500}>
      <button
        disabled={currentPageNumber === 0}
        onClick={() => setCurrentPageNumber((oldVal: number) => oldVal - 1)}
        style={{
          visibility: `${currentPageNumber === 0 ? "hidden" : "visible"}`,
        }}
        className="page-number-button backward"
      >
        {" "}
        &lt;
      </button>
      <div style={{ display: "flex" }}>
        {pages.map((page: GenericObject) => (
          <button
            key={`page_${page.pageNum}`}
            onClick={() => {
              console.log(`Clicked: ${page}`);
              handlePageClick(page.pageNum);
            }}
            style={{
              color: currentPageNumber === page.pageNum ? "blue" : "red",
            }}
            className="page-number-button"
          >
            {page.pageNum + 1}
          </button>
        ))}
      </div>
      <button
        disabled={currentPageNumber + 1 === pages.length}
        onClick={() => setCurrentPageNumber((oldVal: number) => oldVal + 1)}
        className="page-number-button forward"
        style={{
          visibility: `${
            currentPageNumber + 1 === pages.length ? "hidden" : "visible"
          }`,
        }}
      >
        {" "}
        &gt;
      </button>
    </Inline>
  );
};
