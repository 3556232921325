import React from "react";
import Loader from "react-loader-spinner";

const LoadingSpinner = () => {
    return (
        <div
            id="loading-div"
            style={{
                textAlign: "center",
            }}
        >
            <Loader
                type="ThreeDots"
                color="#F74209"
            />
            LOADING
        </div>
    );
};

export default LoadingSpinner;