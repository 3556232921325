import React, { EventHandler, Ref, RefObject } from "react";
import { SoonspinsShow } from "../util";
import {
    Row,
    Carousel,
    Container,
    Button,
    Col,
    Card,
} from "react-bootstrap";
import Footer from "./Footer";
import { AudioPlayerHandle } from './BodyContainer';

type Props = {
    data: SoonspinsShow[];
    activeIndex: number;
    handleSelect: (selectedIndex: number, e: any) => void;
    audioPlayerRef: RefObject<AudioPlayerHandle>;
    playMixcloudAudio: (embedCode: string, audioPlayerRef: RefObject<AudioPlayerHandle>) => void;
};

const SoonspinsCarouselContainer: React.FC<Props> = ({ data, activeIndex, handleSelect, playMixcloudAudio, audioPlayerRef }) => {
    return (
        <div>
            <Container
                id="main-carousel"
                className="no-gutters"
                style={{ width: "90%", zIndex: 0, marginBottom: 24 }}
            >
                <Carousel
                    activeIndex={activeIndex}
                    onSelect={handleSelect}
                    interval={5000}
                >
                    {data.map((contentObject: SoonspinsShow, index: number) => (
                        <Carousel.Item
                            style={{
                                height: "100%",
                                textAlign: "center",
                                position: "relative",
                            }}
                            key={`${contentObject.id}_${contentObject.title}`}
                        >
                            <img
                                src={contentObject.image_url}
                                alt="First slide"
                                style={{ margin: "auto", border: "5px solid white" }}
                            />
                            <Carousel.Caption
                                style={{
                                    textShadow: "3.25px 3.25px #454343",
                                    position: "absolute",
                                    bottom: "21%",
                                    backgroundColor: "#0f0f0f",
                                    border: "5px solid"
                                }}
                            >
                                <h3>{contentObject["title"]}</h3>
                                <p>{contentObject["description"]}</p>
                                <Button
                                    style={{
                                        fontWeight: 700,
                                        textShadow: "2px 2px 2px black",
                                        boxShadow: "2px 2px black",
                                        borderRadius: 0
                                    }}
                                    className="card-button"
                                    onClick={() => playMixcloudAudio(contentObject.embed_code, audioPlayerRef)}
                                    variant="outline-light"
                                    id="button"
                                >
                                    Listen Now
                                </Button>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
            <Footer />
        </div>
    );
};

export const MobileSoonspinsContainer: React.FC<Omit<Props, 'activeIndex' | 'handleSelect'>> = ({ data, playMixcloudAudio, audioPlayerRef }) => {
    return (
        <div>
            <Col>
                {data.map((contentObject: SoonspinsShow, index: number) => (
                    <Row
                        style={{ position: "relative", margin: "8px 0px 8px 0px" }}
                        key={`${contentObject.id}_${contentObject.title}`}
                    >
                        <Card className="soonspins-card" style={{ width: "100%" }}>
                            <Card.Img
                                variant="top"
                                src={contentObject.image_url}
                            />
                            <Card.Body>
                                <Card.Title>{contentObject["title"]}</Card.Title>
                                <Card.Text>{contentObject["description"]}</Card.Text>
                                {
                                    <Button
                                        onClick={() => playMixcloudAudio(contentObject.embed_code, audioPlayerRef)}
                                        variant="outline-light"
                                        id="button"
                                        className="card-button"
                                    >
                                        Listen Now
                                    </Button>
                                }
                                <div
                                    id={`outer-playlist-${index}`}
                                    style={{ marginTop: 20 }}
                                ></div>
                            </Card.Body>
                        </Card>
                    </Row>
                ))}
            </Col>
            <div style={{ marginBottom: 16 }}></div>
            <Footer />
        </div>
    );
};

export default SoonspinsCarouselContainer;