import { getAnalytics } from "firebase/analytics";
import {initializeApp} from "firebase/app";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBTn4UQylZygAwB_s1dA4iow_vd6rtz0SI",
    authDomain: "soonspins-site.firebaseapp.com",
    projectId: "soonspins-site",
    storageBucket: "soonspins-site.appspot.com",
    messagingSenderId: "157584939914",
    appId: "1:157584939914:web:7048643adc18eafae50673",
    measurementId: "G-463X2H2XC5"
  };

const firebaseApp = initializeApp(firebaseConfig);

const firebaseAnalytics = getAnalytics(firebaseApp);

export default firebaseAnalytics