import React, { useState, useEffect } from "react";
import { useRadio } from "../contexts/RadioContext";
import { Source } from "../types";
import styles from "../Assets/css/RadioTicker.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { STATION_BASE_URL } from "../constants";
import useWindowSize from "./useWindowSize";

const RadioTicker: React.FC = () => {
  const { stations, loading } = useRadio();
  const { width } = useWindowSize();
  const isMobile = width !== undefined && width < 768;
  const audioRef = React.useRef<HTMLAudioElement | null>(null);
  const [currentStation, setCurrentStation] = useState<Source | null>(null);
  const [loadingStation, setLoadingStation] = useState<Source | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const resetAudioState = () => {
    setCurrentStation(null);
    setLoadingStation(null);
    setIsPlaying(false);
  };

  const stopStation = async () => {
    if (audioRef.current) {
      await audioRef.current.pause();
      resetAudioState();
    }
  };

  const toggleStation = (station: Source) => {
    const isCurrentStation =
      currentStation && currentStation.server_name === station.server_name;

    if (isCurrentStation) {
      stopStation();
    } else {
      playStation(station);
    }
  };

  const playStation = (station: Source) => {
    if (audioRef.current) {
      audioRef.current.pause(); // Stop the current audio
    } else {
      audioRef.current = new Audio(); // Initialize audio reference
    }

    setShowLoading(true); // Show loading when starting to play a new station
    audioRef.current.src = `${STATION_BASE_URL}/${station.server_name}`; // Set new source
    setLoadingStation(station); // Set loading station

    audioRef.current
      .play()
      .then(() => {
        setShowLoading(false); // Hide loading when play succeeds
        setLoadingStation(null);
        setCurrentStation(station);
        setIsPlaying(true);
        setupMediaSession(station);

        // Ensure audioRef.current is not null before accessing currentTime
        if (audioRef.current) {
          audioRef.current.currentTime = 0; // Start from live position
        }
      })
      .catch(handlePlayError);
  };

  const handlePlayError = (error: any) => {
    console.error("Error playing audio:", error);
    setLoadingStation(null);
    setShowLoading(false); // Hide loading on error
  };

  const setupMediaSession = (station: Source) => {
    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: station.server_name,
        artist: station.title,
      });

      navigator.mediaSession.setActionHandler("play", () =>
        toggleStation(station)
      );
      navigator.mediaSession.setActionHandler("pause", stopStation);
      navigator.mediaSession.setActionHandler("stop", stopStation);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handlePlay = () => {
      setIsPlaying(true);
    };

    if (audio) {
      audio.addEventListener("pause", handlePause);
      audio.addEventListener("play", handlePlay);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("pause", handlePause);
        audio.removeEventListener("play", handlePlay);
      }
    };
  }, [currentStation]);

  useEffect(() => {
    return () => {
      stopStation(); // Clean up audio on component unmount
    };
  }, []);

  return (
    <div className={styles.tickerContainer}>
      <h2 className="title-h1">NOW PLAYING</h2>
      {!showLoading && stations.length === 0 && <p>No stations available.</p>}
      {stations.map((station) => (
        <div key={station.server_name} className={styles.trackItem}>
          {isMobile ? (
            <>
              <div
                id="station-and-song"
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <div className={styles.stationDescription}>
                  {station.server_description}
                </div>
                <span>{station.title || "Unknown Title"}</span>
              </div>
              <button
                className={styles.playButton}
                onClick={() => toggleStation(station)}
              >
                {loadingStation &&
                loadingStation.server_name === station.server_name ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : currentStation &&
                  currentStation.server_name === station.server_name ? (
                  <FontAwesomeIcon icon={faStop} />
                ) : (
                  <FontAwesomeIcon icon={faPlay} />
                )}
              </button>
            </>
          ) : (
            <>
              <div className={styles.stationDescription}>
                {station.server_description}
              </div>
              <span>{station.title || "Unknown Title"}</span>
              <button
                className={styles.playButton}
                onClick={() => toggleStation(station)}
              >
                {loadingStation &&
                loadingStation.server_name === station.server_name ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : currentStation &&
                  currentStation.server_name === station.server_name &&
                  isPlaying ? (
                  <FontAwesomeIcon icon={faStop} />
                ) : (
                  <FontAwesomeIcon icon={faPlay} />
                )}
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default RadioTicker;
